import { useEffect, useMemo } from 'react'
import kebabCase from 'lodash/kebabCase'
import { useFormikContext } from 'formik'

import ReactInputVerificationCode from 'react-input-verification-code'
const Verification = ({ id, name, placeholder, label }) => {
    const { handleBlur, getFieldMeta, setFieldValue, submitForm } =
        useFormikContext() || {}

    const { value } = getFieldMeta ? getFieldMeta(name) : {}
    const id$ = useMemo(() => id || `${kebabCase(label)}`, [id, label])

    useEffect(() => {
        if (parseInt(value) > 9999) {
            submitForm()
        }
    }, [value, submitForm])

    return (
        <div className="verification-form" htmlFor={id$}>
            {label && (
                <span className="bg-common-white text-xs text-gray-400">
                    {label}
                </span>
            )}
            <ReactInputVerificationCode
                id={id$}
                length={5}
                autoFocus={true}
                passwordMask="x"
                placeholder={placeholder}
                onChange={(eValue) => setFieldValue(name, eValue)}
                onBlur={handleBlur}
                value={value}
            />
        </div>
    )
}

export default Verification
