import Head from 'next/head'
import Link from 'next/link'
import { Formik, Form } from 'formik'
import { useEffect, useState, useMemo, useCallback } from 'react'

import Input from '@/components/base/TextBox'
import Checkbox from '@/components/base/Checkbox'
import Button from '@/components/base/Button'
import Alert from '@/components/base/Alert'

import Verification from '@/components/base/Verification'

import { useSession } from '@/contexts/Session'
import loginValidation from '@/validations/login'

import useFetcher from '@/redux/hooks/useFetcher'

import dayjs from '@/data/dayjs'

const AuthLogin = () => {
    const [successMessage, setSuccessMessage] = useState(null)
    const [otpMobileNumber, setOtpMobileNumber] = useState(false)

    const { selectedItem } = useFetcher()
    const { authToken, setAuthToken } = useSession()

    const initialState = useMemo(
        () => ({
            trust: true,
            password: '',
            remember: false,
            email: selectedItem,
            verificationCode: '',
            sendOtpVia: 'mobileNumber',
        }),
        [selectedItem]
    )

    const handleSubmit = useCallback(
        async (
            { email, trust, password, remember, verificationCode, sendOtpVia },
            { setErrors, setFieldValue, setSubmitting }
        ) => {
            setSubmitting(true)

            const signInUrl = `${process.env.NEXT_PUBLIC_API_URL}/auth/login`

            const fetchOptions = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    trust,
                    password,
                    remember,
                    sendOtpVia,
                    permission: 'admin-login',
                    verificationCode: parseInt(verificationCode),
                    trustToken: authToken.trustToken,
                }),
            }

            const response = await fetch(signInUrl, fetchOptions)

            const data = await response.json()

            if (response.ok) {
                if (data.data.accessToken) {
                    setAuthToken('accessToken', data.data.accessToken, {
                        path: '/',
                    })

                    setAuthToken('refreshToken', data.data.refreshToken, {
                        path: '/',
                    })

                    if (data.data.trustToken) {
                        setAuthToken('trustToken', data.data.trustToken, {
                            path: '/',
                            expires: dayjs().add(7, 'day').toDate(),
                        })
                    }
                } else {
                    if (sendOtpVia === 'email') {
                        setSuccessMessage(
                            'We have sent you a code to email address, please check your inbox.'
                        )
                    }
                    setOtpMobileNumber(data.data.mobileNumber)
                    setFieldValue('sendOtpVia', 'mobileNumber')
                }
            } else {
                setErrors({
                    email: data.message,
                    password: data.message,
                })
            }

            setSubmitting(false)
        },
        [authToken, setOtpMobileNumber, setAuthToken]
    )

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null)
            }, 2000)
            return () => clearTimeout(timer)
        }
    }, [successMessage, setSuccessMessage])

    return (
        <>
            <Head>
                <title>{`Sign in | ${process.env.NEXT_PUBLIC_APP_NAME}`}</title>
            </Head>
            <div>
                <h2 className="auth-heading mb-6">
                    {!otpMobileNumber
                        ? 'Sign In To Your Account'
                        : 'Enter your unique code'}
                </h2>
            </div>
            <Formik
                initialValues={initialState}
                onSubmit={handleSubmit}
                validationSchema={loginValidation}
                validateOnBlur={false}
            >
                {({ errors, touched, setFieldValue, submitForm }) => {
                    const firstKey = Object.keys(errors)[0]
                    const showError = !!errors[firstKey] && !!touched[firstKey]

                    return (
                        <Form noValidate>
                            {!otpMobileNumber && (
                                <div>
                                    <div>
                                        <Input
                                            id="email"
                                            name="email"
                                            type="email"
                                            label="Email address"
                                            placeholder="Enter your email address"
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            id="password"
                                            name="password"
                                            type="password"
                                            label="Password"
                                            placeholder="Enter your account password"
                                        />
                                    </div>
                                </div>
                            )}
                            {!otpMobileNumber && (
                                <div className="mb-5 grid grid-cols-2">
                                    <div className="grid grid-cols-1">
                                        <Checkbox
                                            id="remember"
                                            name="remember"
                                            label="Remember me"
                                        />
                                    </div>
                                    <div className="relative top-0.5 ml-auto text-right">
                                        <Link href="/password/request">
                                            <a className="font-acumin font-themeFont text-primary-500">
                                                Forgot password?
                                                <span className="custom-underline"></span>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            )}
                            {otpMobileNumber && (
                                <>
                                    <p className="text-base">
                                        We have sent you a code to your phone
                                        number ending in:{' '}
                                        {otpMobileNumber.slice(-4)}
                                    </p>
                                    <p className="text-base">
                                        To complete your login process, please
                                        enter the 5-digit code.
                                    </p>
                                </>
                            )}
                            {otpMobileNumber && (
                                <div>
                                    <div>
                                        <Verification
                                            id="verificationCode"
                                            name="verificationCode"
                                            placeholder="x"
                                        />
                                    </div>
                                </div>
                            )}
                            {otpMobileNumber && (
                                <div className="mb-5 grid grid-cols-1">
                                    <div className="grid grid-cols-1">
                                        <Checkbox
                                            id="trust"
                                            name="trust"
                                            label="Trust this device for future sessions"
                                        />
                                    </div>
                                </div>
                            )}
                            <div>
                                {showError && (
                                    <Alert
                                        variant="danger"
                                        message={errors[firstKey]}
                                        icon="warning"
                                        iconStyle="text-danger-500 pr-2 text-xs"
                                    ></Alert>
                                )}
                                {successMessage && (
                                    <div className="mt-6">
                                        <Alert
                                            variant="success"
                                            message={successMessage}
                                            icon="check"
                                            iconStyle="text-success-500 pr-2 text-xs"
                                        ></Alert>
                                    </div>
                                )}
                            </div>
                            <div>
                                <Button
                                    text={
                                        !otpMobileNumber ? 'SIGN IN' : 'VERIFY'
                                    }
                                    type="submit"
                                    variant="primary"
                                    modification="w-full"
                                    id="signin_submit"
                                />
                                {otpMobileNumber && (
                                    <div className="mt-1 text-center">
                                        <a
                                            className="cursor-pointer text-primary-500"
                                            onClick={() => {
                                                setFieldValue(
                                                    'sendOtpVia',
                                                    'email'
                                                )
                                                submitForm()
                                            }}
                                        >
                                            Can’t access your cell phone?
                                        </a>
                                    </div>
                                )}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

AuthLogin.guest = true

export default AuthLogin
